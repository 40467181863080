@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;600&display=swap');

::selection {
  background-color: #7d7d7d48;
  color: #fff;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: #0e0e0e
}

::-webkit-scrollbar-thumb {
  background-color: #522d0560;
  border-radius: 0.25rem;
}

*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none
}
html, body {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  background: #1f1f1f;

}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat', sans-serif;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
  height: 100%;
}